<template>
  <div>
    <b-row>
      <b-col
        inline
        md="2"
        xl="2"
        sm="12"
        mb="12"
      >
        <div
          id="minicalendar-navigation"
        />
      </b-col>
      <b-col
        inline
        md="10"
        xl="10"
        sm="12"
        mb="12"
      >
        <div ref="scheduler" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import 'dhtmlx-scheduler'
import 'dhtmlx-scheduler/codebase/ext/dhtmlxscheduler_container_autoresize'
import 'dhtmlx-scheduler/codebase/ext/dhtmlxscheduler_minical'
import 'dhtmlx-scheduler/codebase/ext/dhtmlxscheduler_limit'
import defaultAppointmentCalendarEvent from '@/components/stylist/stylist-calendar/defaultAppointmentCalendarEvent'
import { BCol, BRow } from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import moment from 'moment'

export default {
  name: 'StylistScheduler',
  components: {
    BCol,
    BRow,
  },
  props: {
    events: {
      type: Array,
      default() {
        return { events: [] }
      },
    },
  },
  computed: {
    ...mapState('StylistCalendarStoreModule', {
      appointmentDeleteStore: state => state.appointmentDelete,
      appointments: state => state.appointments,
      openHours: state => state.openHours,
    }),
  },
  watch: {
    events: {
      handler(appointments) {
        if (appointments) {
          // eslint-disable-next-line
          scheduler.clearAll()
          // eslint-disable-next-line
          scheduler.parse(appointments)
        }
      },
    },
    appointmentDeleteStore: {
      deep: true,
      handler(appointmentDelete) {
        if (appointmentDelete.id) {
          // eslint-disable-next-line
          scheduler.deleteEvent(appointmentDelete.id)
          this.clearAppointmentDelete()
        }
      },
    },
    openHours: {
      deep: true,
      handler(openHours) {
        if (openHours.response) {
          this.deleteMarkTimespanForOpenHours(openHours.response)
          this.fetchAppointments()
        }
      },
    },
    appointments: {
      deep: true,
      handler(appointments) {
        if (appointments.response) {
          this.trimCalendar(this.openHours.response, appointments.response)
        }
      },
    },
  },
  mounted() {
    this.fetchOpenHours()
    // eslint-disable-next-line
    scheduler.skin = 'flat'
    // eslint-disable-next-line
    scheduler.config.readonly = true
    // eslint-disable-next-line
    scheduler.config.header = [
      'day',
      'week',
      'month',
      'date',
      'prev',
      'today',
      'next',
    ]
    // eslint-disable-next-line
    scheduler.config.time_step = 15
    // eslint-disable-next-line
    scheduler.config.hour_date = "%h:%i %A"
    // eslint-disable-next-line
    scheduler.config.minicalendar.mark_events = false
    // eslint-disable-next-line
    scheduler.config.cascade_event_display = true
    // eslint-disable-next-line
    scheduler.config.cascade_event_count = 4
    // eslint-disable-next-line
    scheduler.config.cascade_event_margin = 30
    // eslint-disable-next-line
    scheduler.config.separate_short_events = true;
    // eslint-disable-next-line
    scheduler.deleteMarkedTimespan()
    this.setFullWeekNotWorkingHours()
    this.displayHighlight()
    // eslint-disable-next-line
    scheduler.config.start_on_monday = false;
    // eslint-disable-next-line
    scheduler.init(this.$refs.scheduler, new Date(), 'week')
    // eslint-disable-next-line
    scheduler.templates.hour_scale = function(date){
      // eslint-disable-next-line
      return scheduler.date.date_to_str("%h %A")(date)
    }
    // eslint-disable-next-line
    scheduler.templates.event_class = function(start, end, ev) {
      const state = ev.state || 'pending'

      return `state-${state}`
    }
    // eslint-disable-next-line
    this.saveSelectedPeriod(scheduler.getState().min_date, scheduler.getState().max_date)
    // eslint-disable-next-line
    scheduler.attachEvent('onViewChange', (new_mode, new_date) => {
      // eslint-disable-next-line
      this.saveSelectedPeriod(scheduler.getState().min_date, scheduler.getState().max_date)
    })
    // eslint-disable-next-line
    scheduler.parse(this.$props.events)
    // eslint-disable-next-line
    scheduler.attachEvent('onClick', (id, e) => {
      // eslint-disable-next-line
      this.showAppointmentContextMenuModal(scheduler.getEvent(id))
      return true
    })
    // eslint-disable-next-line
    scheduler.renderCalendar({
      container: 'minicalendar-navigation',
      navigation: true,
      handler(date) {
        // eslint-disable-next-line
        scheduler.setCurrentView(date, scheduler._mode)
      },
    })
    // eslint-disable-next-line
    scheduler.attachEvent('onEmptyClick', (date, e) => {
      const appointment = { ...defaultAppointmentCalendarEvent, start_date: date }
      this.showAppointmentModal(appointment)
      return true
    })
  },
  methods: {
    ...mapActions('StylistCalendarStoreModule', [
      'clearAppointmentDelete',
      'fetchOpenHours',
      'fetchAppointments',
    ]),
    saveSelectedPeriod(startDate, endDate) {
      this.$store.dispatch('StylistCalendarStoreModule/saveSelectedPeriod', { start: startDate, end: endDate })
    },
    showAppointmentModal(appointment) {
      this.$store.dispatch('StylistCalendarStoreModule/showAppointmentModal', appointment)
    },
    showAppointmentContextMenuModal(appointment) {
      this.$store.dispatch('StylistCalendarStoreModule/showAppointmentContextMenuModal', appointment)
      this.$store.dispatch('StylistCalendarStoreModule/fetchAppointmentCardDetails', appointment)
    },
    deleteMarkTimespanForOpenHours(openHours) {
      openHours.forEach(openHour => {
        const endTime = moment(openHour.endTime, 'HH:mm')
        const startTime = moment(openHour.startTime, 'HH:mm')
        // eslint-disable-next-line
        scheduler.deleteMarkedTimespan({
          // eslint-disable-next-line
          days:  (openHour.weekDay + 1) % 7,
          // eslint-disable-next-line
          zones:[startTime.format('H') * 60, endTime.format('H') * 60],
        })
      })
    },
    trimCalendar(openHours, appointments) {
      let minTime = null
      let maxTime = null
      openHours.forEach(openHour => {
        const endTime = moment(openHour.endTime, 'HH:mm')
        const startTime = moment(openHour.startTime, 'HH:mm')
        if (minTime == null || minTime > +startTime.format('H')) {
          minTime = startTime.format('H')
        }
        if (maxTime == null || maxTime < +endTime.format('H')) {
          maxTime = endTime.format('H')
        }
      })
      appointments.forEach(appointment => {
        const endTime = moment(appointment.endsAt, 'YYYY-MM-DD H:mm')
        const startTime = moment(appointment.startsAt, 'YYYY-MM-DD H:mm')
        if (minTime == null || minTime > +startTime.format('H')) {
          minTime = startTime.startOf('hour').format('H')
        }
        if (maxTime == null || maxTime < +endTime.format('H')) {
          if (endTime.isBefore(moment(endTime).endOf('hour'))) {
            endTime.add(1, 'h')
          }
          maxTime = endTime.endOf('hour').format('H')
        }
      })
      // eslint-disable-next-line
      scheduler.config.first_hour = minTime
      // eslint-disable-next-line
      scheduler.config.last_hour = maxTime
      // eslint-disable-next-line
      scheduler.updateView()
    },
    setFullWeekNotWorkingHours() {
      // eslint-disable-next-line
      scheduler.addMarkedTimespan({
        // eslint-disable-next-line
        days:  [0,1,2,3,4,5,6],
        // eslint-disable-next-line
        zones: "fullday",
        // eslint-disable-next-line
        css:   "gray-section",
      })
    },
    displayHighlight() {
      // eslint-disable-next-line
      scheduler.attachEvent('onTemplatesReady', function() {
        let marked = null
        let markedDate = null

        // eslint-disable-next-line
        scheduler.attachEvent('onMouseMove', function(event_id, native_event) {
          // eslint-disable-next-line
          const date = scheduler.getActionData(native_event).date

          if (+date !== +markedDate) {
            // eslint-disable-next-line
            scheduler.unmarkTimespan(marked)

            markedDate = date
            // eslint-disable-next-line
            marked = scheduler.markTimespan({
              start_date: date,
              // eslint-disable-next-line
              end_date: scheduler.date.add(date, scheduler.config.time_step, 'minute'),
              css: 'highlighted-timespan',
            })
          }
        })
      })
    },
  },
}
</script>
<style lang="scss">
@import "~dhtmlx-scheduler/codebase/dhtmlxscheduler_flat.css";
@import "src/assets/scss/variables/state-color-codes.scss";
@import "src/@core/scss/base/bootstrap-extended/_variables.scss";
@import "src/assets/scss/variables/_variables.scss";
.dhx_cal_tab.active, .dhx_mini_calendar .dhx_calendar_click { background-color: $primary; color: #fff; }
.dhx_cal_tab { color: $body-color; }
.dhx_cal_today_button, .dhx_cal_navline { color: $body-color; }
.dhx_mini_calendar {
  box-shadow: none !important;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.dhx_cal_event {
  .dhx_title, .dhx_body{
    background-color: $primary;
  }
  @each $state, $color in $state-colors {
    &.state-#{$state} {
      .dhx_title, .dhx_body {
        background-color: $color;
      }
    }
  }
  z-index: 5;
}
.gray-section {
  background-color: $red;
  background-color: #ffaaaa;
  opacity: 0.15;
}
.highlighted-timespan {
  background-color: #87cefa;
  opacity:0.5;
  cursor: pointer;
  z-index: 0;
}
.dhtmlx_modal_box,
.dhx_cal_event_line,
textarea.dhx_cal_editor,
.dhx_cal_light,
.dhx_cal_lsection,
.dhx_cal_lsection .dhx_fullday,
.dhx_cal_ltext textarea,
.dhx_custom_button,
.dhx_matrix_scell,.dhx_timeline_scale_header,
.dhtmlXTooltip.tooltip,
.dhx_cal_quick_info,
.dhx_cal_container,
.dhx_cal_navline .dhx_cal_date,
.dhx_mini_calendar .dhx_year_month,
.dhx_mini_calendar,
.dhx_mini_calendar .dhx_month_head,
.dhx_mini_calendar .dhx_scale_bar{
  font-family: inherit;
}
.dhx_scale_holder_now{
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAsCAIAAAArRUU2AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAGUlEQVQI12P4//8/A7Xx69evGWhh7rlz5wAftYKuAmb8AgAAAABJRU5ErkJggg==);
  background-color: #ffffdd;
  background-blend-mode: multiply;
}
.dhx_cal_event .dhx_body,
.dhx_cal_event.dhx_cal_select_menu .dhx_body{
  font-size: 11px;
  text-align: center;
  line-height: 1.2;
}
</style>
