<template>
  <div>
    <b-modal
      id="modal-calendar-appointment"
      v-model="appointmentStateExtraConfirmToChargeModal.show"
      no-close-on-backdrop
      modal-class="modal-primary"
      :hide-footer="true"
      centered
      size="sm"
    >
      <template #modal-header>
        <h4>{{ appointmentContextMenuModal.details.services[0].name }} - {{ appointmentContextMenuModal.details.customer.name }} {{ appointmentContextMenuModal.details.customer.lastName }}</h4>
        <p class="mb-0">
          {{ appointmentStart }}
        </p>
      </template>
      <!-- BODY -->
      <div>
        <b-overlay
          :show="customerFeeAvailabilityStore.isPending"
          variant="secondary"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="lg"
        >

          <b-button
            v-if="customerFeeAvailability && !customerFeeAvailabilityStore.isPending"
            variant="danger"
            block
            @click="changeStateAndCharge(appointmentStateExtraConfirmToChargeModal.state)"
          >
            Charge {{ transitionStateNames[appointmentStateExtraConfirmToChargeModal.state] }} fee
          </b-button>
          <b-alert
            variant="danger"
            :show="!customerFeeAvailability && !customerFeeAvailabilityStore.isPending"
          >
            <div class="alert-body">
              <span>Regarding your policy you can't charge this person</span>
            </div>
          </b-alert>
          <b-button
            variant="primary"
            block
            @click="changeState(appointmentStateExtraConfirmToChargeModal.state)"
          >
            Do not charge {{ transitionStateNames[appointmentStateExtraConfirmToChargeModal.state] }} fee
          </b-button>
          <b-button
            variant="outline-secondary"
            block
            @click="hideAppointmentStatusExtraConfirmToChargeModal()"
          >
            Cancel
          </b-button>
        </b-overlay>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  required, regex,
} from '@validations'
import { mapActions, mapState } from 'vuex'
import {
  BModal, BButton, BAlert, BOverlay,
} from 'bootstrap-vue'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'StylistCalendarAppointmentCheckoutModal',
  components: {
    BModal,
    BButton,
    BAlert,
    BOverlay,
  },
  data() {
    return {
      appointmentStart: '',
      modalShow: false,
      required,
      regex,
      transitionStateNames: {
        cancel: 'cancellation',
        no_show: 'no-show',
      },
      customerFeeAvailability: false,
    }
  },
  computed: {
    ...mapState('StylistCalendarStoreModule', {
      appointmentStateExtraConfirmToChargeModal: state => state.appointmentStateExtraConfirmToChargeModal,
      appointmentContextMenuModal: state => state.appointmentContextMenuModal,
      chargeCustomerFeeError: state => state.chargeCustomer.error.response,
      customerFeeAvailabilityStore: state => state.feeAvailability,
    }),
  },
  watch: {
    customerFeeAvailabilityStore: {
      deep: true,
      handler(newStripeAccount) {
        if (newStripeAccount.response) {
          this.customerFeeAvailability = newStripeAccount.response
        }
      },
    },
  },
  mounted() {
    this.appointmentStart = moment(this.appointmentContextMenuModal.details.startsAt).format('MMMM Do YYYY, h:mm:ss a')
    this.getCustomerFeeAvailability({ appointmentId: this.appointmentContextMenuModal.details.id, type: this.transitionStateNames[this.appointmentStateExtraConfirmToChargeModal.state] })
  },
  methods: {
    ...mapActions('StylistCalendarStoreModule', [
      'hideAppointmentStatusExtraConfirmToChargeModal',
      'hideAppointmentContextMenuModal',
      'changeAppointmentState',
      'chargeCustomerCancelFee',
      'chargeCustomerNoShowFee',
      'getCustomerFeeAvailability',
    ]),
    changeState(transition) {
      const appointmentId = this.appointmentContextMenuModal.details.id

      this.changeAppointmentState({
        appointmentId,
        transition,
      }).then(() => {
        this.$store.dispatch('StylistCalendarStoreModule/fetchAppointments')
        this.hideAppointmentStatusExtraConfirmToChargeModal()
        this.hideAppointmentContextMenuModal()
      })
    },
    async changeStateAndCharge(transition) {
      const appointmentId = this.appointmentContextMenuModal.details.id
      try {
        if (transition === 'cancel') {
          await this.chargeCustomerCancelFee(appointmentId)
        }

        if (transition === 'no_show') {
          await this.chargeCustomerNoShowFee(appointmentId)
        }
        this.changeState(transition)
      } catch {
        this.error(this.chargeCustomerFeeError.data)
      }
    },
    error(msg) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: msg,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import "~dhtmlx-scheduler/codebase/dhtmlxscheduler_flat.css";

.cursor-pointer .input {
  opacity: 1 !important;
}
</style>
