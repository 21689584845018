<template>
  <div class="app-calendar overflow-hidden border">
    <b-overlay
      :show="appointments.isPending"
      bg-color="#ffffff"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="lg"
    >
      <div class="row no-gutters">

        <div class="col position-relative">
          <div class="card shadow-none border-0 mb-0 rounded-0">
            <div class="card-body pb-0 padding-0">
              <button
                class="btn-new-event"
                @click="addNewEvent"
              >+</button>
              <stylist-scheduler
                :events="mapToCalendar(appointments.response)"
              />
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
    <stylist-calendar-appointment-modal v-if="appointmentModal.show" />
    <stylist-calendar-appointment-context-menu-modal v-if="appointmentContextMenuModal.show" />
    <stylist-calendar-appointment-checkout-modal v-if="appointmentCheckoutModal.show" />
    <stylist-calendar-appointment-state-extra-confirm-to-charge-modal v-if="appointmentStateExtraConfirmToChargeModal.show" />
  </div>
</template>
<script>

import StylistScheduler from '@/components/stylist/stylist-calendar/StylistScheduler.vue'
import { BOverlay } from 'bootstrap-vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import moment from 'moment-timezone'
import { TYPE } from 'vue-toastification'
import { AlertTriangleIcon } from 'vue-feather-icons'
import StylistCalendarAppointmentModal from '@/components/stylist/stylist-calendar/StylistCalendarAppointmentModal.vue'
import StylistCalendarAppointmentContextMenuModal from '@/components/stylist/stylist-calendar/StylistCalendarAppointmentContextMenuModal.vue'
import StylistCalendarAppointmentCheckoutModal from '@/components/stylist/stylist-calendar/StylistCalendarAppointmentCheckoutModal.vue'
import StylistCalendarAppointmentStateExtraConfirmToChargeModal from '@/components/stylist/stylist-calendar/StylistCalendarAppointmentStateExtraConfirmToChargeModal.vue'
import jwtDecode from 'jwt-decode'

export default {
  components: {
    StylistCalendarAppointmentContextMenuModal,
    StylistCalendarAppointmentCheckoutModal,
    StylistCalendarAppointmentModal,
    StylistCalendarAppointmentStateExtraConfirmToChargeModal,
    StylistScheduler,
    BOverlay,
  },
  data() {
    return {
      appointments: {
        response: [],
        isPending: true,
        error: null,
      },
    }
  },
  computed: {
    ...mapState('StylistCalendarStoreModule', {
      selectedPeriod: state => state.period,
      appointmentsStore: state => state.appointments,
      appointmentModal: state => state.appointmentModal,
      appointmentContextMenuModal: state => state.appointmentContextMenuModal,
      appointmentStateExtraConfirmToChargeModal: state => state.appointmentStateExtraConfirmToChargeModal,
      appointmentCheckoutModal: state => state.appointmentCheckoutModal,
      newCustomerModal: state => state.newCustomer,
      stylistStore: state => state.stylist,
    }),
  },
  watch: {
    selectedPeriod: {
      deep: true,
      handler(period) {
        if (period) {
          const startDate = moment(period.start).format('yyyy-MM-DD')
          const endDate = moment(period.end).format('yyyy-MM-DD')
          this.getAppointments(startDate, endDate)
        }
      },
    },
    appointmentsStore: {
      deep: true,
      handler(newAppointments) {
        this.appointments = newAppointments
        if (newAppointments.error) {
          this.$toast('Error fetching appointments', {
            icon: AlertTriangleIcon,
            type: TYPE.ERROR,
          })
        }
      },
    },
    appointmentModal: {
      deep: true,
      handler(appointment) {
        if (appointment.error) {
          this.$toast('Something went wrong!', {
            icon: AlertTriangleIcon,
            type: TYPE.ERROR,
          })
          this.clearAppointmentModal()
        }
      },
    },
    newCustomerModal: {
      deep: true,
      handler(newCustomer) {
        if (newCustomer.error) {
          this.$toast('Something went wrong!', {
            icon: AlertTriangleIcon,
            type: TYPE.ERROR,
          })
          this.clearNewCustomerModal()
        }
      },
    },
  },
  created() {
    const { stylistId } = jwtDecode(this.$auth.apiToken)
    this.fetchStylist(stylistId)
  },
  methods: {
    ...mapMutations('StylistCalendarStoreModule', {
      clearAppointmentModal: 'CLEAR_APPOINTMENT_MODAL_ERROR',
      clearNewCustomerModal: 'CLEAR_NEW_CUSTOMER_MODAL_ERROR',
    }),
    ...mapActions('StylistCalendarStoreModule', [
      'fetchStylist',
    ]),
    getAppointments(startDate, endDate) {
      this.$store.dispatch('StylistCalendarStoreModule/fetchAppointments', { startDate, endDate })
    },
    mapToCalendar(appointments) {
      if (appointments.length > 0) {
        return appointments.map(obj => ({
          id: obj.id,
          start_date: moment(obj.startsAt, 'yyyy-MM-DDTHH:mm:ss').format('yyyy-MM-DD HH:mm:ss'),
          end_date: moment(obj.endsAt, 'yyyy-MM-DDTHH:mm:ss').format('yyyy-MM-DD HH:mm:ss'),
          text: `${obj.services.map(service => service.name).join(', ')} - ${obj.customer.name || ''} ${obj.customer.lastName || ''}`,
          customer: obj.customer,
          services: obj.services,
          amount: obj.amount,
          note: obj.note,
          startsAt: moment(obj.startsAt, 'yyyy-MM-DDTHH:mm:ss').format('yyyy-MM-DD HH:mm:ss'),
          endsAt: moment(obj.endsAt, 'yyyy-MM-DDTHH:mm:ss').format('yyyy-MM-DD HH:mm:ss'),
          state: obj.status,
          stripeCardId: obj.stripeCardId,
        }))
      }
      return []
    },
    addNewEvent() {
      this.appointmentModal.details = {
        id: null,
        start_date: new Date(),
      }
      this.appointmentModal.show = true
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/apps/calendar.scss";
@import "src/@core/scss/base/bootstrap-extended/_variables.scss";

.padding-0 {
  padding: 0;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 5;
  width: 100%;
  height: 100%;
}

.btn-new-event {
  position: absolute;
  right: 1.5rem;
  bottom: 1.5rem;
  z-index: 10;
  height: 60px;
  width: 60px;
  color: $primary;
  border-radius: 50%;
  font-size: 36px;
  display: inline-block;
  border-color: transparent;
  background-color: white;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}

</style>
