<template>
  <div>
    <b-modal
      id="modal-calendar-appointment"
      v-model="appointmentCheckoutModal.show"
      :hide-footer="true"
      no-close-on-backdrop
      modal-class="modal-primary"
      centered
      size="sm"
    >
      <template #modal-header>
        <h4>{{ appointmentContextMenuModal.details.services[0].name }} - {{ appointmentContextMenuModal.details.customer.name }} {{ appointmentContextMenuModal.details.customer.lastName }}</h4>
        <p class="mb-0">
          {{ appointmentStart }}
        </p>
      </template>
      <!-- BODY -->
      <validation-observer
        ref="refFormObserver"
      >
        <b-overlay
          :show="appointmentCardDetailsStore.isPending || appointmentCheckoutPayment.isPending"
          variant="secondary"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="lg"
        >
          <!-- Form -->
          <b-form
            id="checkout-form"
            slot-scope="{ validate }"
            class="pt-0"
            @submit.prevent="submitAppointmentForm(validate())"
          >
            <b-row>
              <b-col
                md="12"
                xl="12"
                sm="12"
              >
                <b-row
                  md="12"
                  xl="12"
                  sm="12"
                >
                  <b-col
                    inline
                    md="12"
                    xl="12"
                    sm="12"
                    mb="0"
                  >
                    <!-- Price -->
                    <validation-provider
                      #default="{ valid, errors }"
                      name="Price"
                      rules="required|regex:^(([1-9])*([0])*)(\.(\d{1})(\d{1})?)?$"
                    >
                      <b-form-group
                        label="Price ($)"
                        label-for="price"
                      >
                        <b-form-input
                          id="amount"
                          v-model="payment.amount"
                          trim
                          :state="errors[0] ? false : null"
                          placeholder="0.00$"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                    <b-form-group
                      label="Checkout Note"
                      label-for="note"
                    >
                      <b-form-textarea
                        id="payment-note"
                        v-model="payment.note"
                        rows="4"
                        placeholder=""
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col></b-row>
            <b-alert
              variant="danger"
              :show="!!appointmentCheckoutPayment.error"
            >
              <div class="alert-body">
                <span>{{ errorMsg }}</span>
              </div>
            </b-alert>
            <b-button
              v-if="appointmentContextMenuModal.details.stripeCardId && appointmentCardDetailsStore.card.last4 && stylist.response.stripeAccountEnabled"
              variant="danger"
              block
              @click="checkoutPayment('card')"
            >
              Pay with CC ending with {{ appointmentCardDetailsStore.card.last4 }}
            </b-button>
            <b-button
              variant="primary"
              block
              @click="checkoutPayment('cash')"
            >
              Pay with Cash
            </b-button>
            <b-button
              class="close-btn"
              variant="secondary"
              block
              @click="cancel()"
            >
              Cancel
            </b-button></b-form>
        </b-overlay>
      </validation-observer>

    </b-modal>
    <b-modal
      v-model="modalShow"
      title="Confirm payment"
      class="modal modal-danger"
      ok-title="Confirm"
      @ok="confirmPayment()"
    >
      <span>Do you really want to confirm the payment?</span>
    </b-modal>

  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, regex,
} from '@validations'
import { mapActions, mapState } from 'vuex'
import {
  BOverlay, BModal, BFormGroup, BFormInput, BForm, BRow, BCol, BFormTextarea, BButton, BAlert,
} from 'bootstrap-vue'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'StylistCalendarAppointmentCheckoutModal',
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BForm,
    BRow,
    BCol,
    BAlert,
    BFormTextarea,
    BButton,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      appointmentStart: '',
      modalShow: false,
      payment: {
        note: '',
        amount: '',
        appointmentId: '',
        method: '',
      },
      errorMsg: '',
      required,
      regex,
    }
  },
  computed: {
    ...mapState('StylistCalendarStoreModule', {
      appointmentCheckoutModal: state => state.appointmentCheckoutModal,
      appointmentContextMenuModal: state => state.appointmentContextMenuModal,
      appointmentCardDetailsStore: state => state.appointmentCardDetails,
      appointmentCheckoutPayment: state => state.appointmentCheckoutPayment,
      stylist: state => state.stylist,
    }),
  },
  watch: {
    appointmentCheckoutPayment: {
      deep: true,
      handler(newAppointmentCheckoutPayment) {
        if (newAppointmentCheckoutPayment.error) {
          this.errorMsg = newAppointmentCheckoutPayment.error.data
        }
      },
    },
  },
  mounted() {
    this.appointmentStart = moment(this.appointmentContextMenuModal.details.startsAt).format('MMMM Do YYYY, h:mm:ss a')
    this.payment.amount = this.appointmentContextMenuModal.details.amount
    this.payment.appointmentId = this.appointmentContextMenuModal.details.id
  },
  methods: {
    ...mapActions('StylistCalendarStoreModule', [
      'checkoutAppointmentPayment',
      'hideAppointmentCheckoutModal',
    ]),
    submitAppointmentForm(validator) {
      validator.then(success => {
        if (success) {
          this.modalShow = !this.modalShow
        }
      })
    },
    async checkoutPayment(paymentForm) {
      const success = await this.$refs.refFormObserver.validate()
      if (success) {
        this.payment.method = paymentForm
        this.modalShow = !this.modalShow
      }
    },
    async confirmPayment() {
      try {
        this.modalShow = !this.modalShow
        await this.checkoutAppointmentPayment(this.payment)
        this.hideModals()
        this.success()
      } catch (e) {
        this.error()
      }
    },
    success() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Data has been updated',
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      })
    },
    error() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Something went wrong. Please try again.',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    },
    cancel() {
      this.hideAppointmentCheckoutModal()
    },
    hideModals() {
      this.$store.dispatch('StylistCalendarStoreModule/hideAppointmentContextMenuModal')
      this.$store.dispatch('StylistCalendarStoreModule/hideAppointmentCheckoutModal')
      this.$store.dispatch('StylistCalendarStoreModule/fetchAppointments')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/apps/calendar.scss';
@import "~dhtmlx-scheduler/codebase/dhtmlxscheduler_flat.css";

.cursor-pointer .input {
  opacity: 1 !important;
}
</style>
